import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { INotificationItem } from './NotificationItem';
import { nanoid } from 'nanoid';

type NotificationsState = {
    notifications: INotificationItem[];
};

const initialState: NotificationsState = {
    notifications: [],
};

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        addNotification: (state, { payload }: PayloadAction<Omit<INotificationItem, 'id'>>) => {
            const notification: INotificationItem = {
                id: nanoid(),
                ...payload,
            };

            state.notifications.push(notification);
        },
        dismissNotification: (state, { payload }: PayloadAction<INotificationItem['id']>) => {
            const index = state.notifications.findIndex(
                (notification) => notification.id === payload
            );

            if (index !== -1) {
                state.notifications.splice(index, 1);
            }
        },
    },
});

export const { addNotification, dismissNotification } = notificationsSlice.actions;
export const notificationReducer = notificationsSlice.reducer;

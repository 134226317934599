export const runStatuses = ['PASSED', 'PASSED_HAS_WARNINGS', 'FAILED','FAILED_ENSURE', 'CRASHED', 'INITIAL_CRASH'];

export const runStatuseCode = {
    PASSED: '0',
    FAILED: '1',
    CRASHED: '2',
    INITIAL_CRASH: '3',
    PASSED_HAS_WARNINGS: '4',
    FAILED_ENSURE: '5'
};
 
export const ARCHIVE_TYPE = {
    0: 'all',
    1: 'byMobileDevice',
    2: 'all_v2',
    3: 'm_v3',
};

export const DefaultFilter = {
    page: 1,
    limit: 25,
    run_id: [],
    user_id: null,
};

export const InitialFilterProps = {
    limit: '25',
    page: 1,
};

import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { IRun, IRunFilters } from './types';
import { RUN_API_REDUCER_KEY } from '../consts';

export const runApi = createApi({
    reducerPath: RUN_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Run', 'OneRunInfo'],
    endpoints: (builder) => ({
        // * fetch all runs with filters
        fetchRuns: builder.query<IRun, Partial<IRunFilters>>({
            query: (params: any) => ({
                url: '/runs/getAllRunsWithReports',
                method: 'GET',
                params: params,
                tagTypes: ['Run'],
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get RUNs options
        fetchSelectRunsOptions: builder.query<IRun, Partial<IRunFilters>>({
            query: (params: any) => ({
                url: `/runs/getSelectRunsOptions`,
                method: 'GET',
                params: params,
                tagTypes: ['Run'],
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * generate Download Link
        generateDownloadLink: builder.mutation<IRun, Partial<IRunFilters>>({
            query: ({ runId }) => ({
                url: `/runs/run`,
                method: 'PUT',
                params: runId,
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * generate Download Link
        convertRequest: builder.mutation<{ message: string; uuid: string }, number>({
            query: (runId: any) => ({
                url: `/converter/converter`,
                method: 'POST',
                data: { run_id: runId },
            }),
            invalidatesTags: ['OneRunInfo'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get run info by id
        getRunInfo: builder.query<IRun, number>({
            query: (runId: any) => ({
                url: `/runs/run`,
                method: 'GET',
                params: { runId },
            }),
            providesTags: ['OneRunInfo'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get converted PDF Status by convertion uuid
        getPDFConnertedStatus: builder.query<{ status: string | null }, string>({
            query: (convertionUuid: any) => ({
                url: `/converter/converter/status`,
                method: 'GET',
                params: { id: convertionUuid ? convertionUuid : -1 },
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * request Run Files

        requestRunFiles: builder.mutation<IRun, Partial<IRunFilters>>({
            query: ({ runId, type }) => ({
                url: `/runs/reports/archive/${runId}`,
                method: 'POST',
                data: { type },
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),
    }),
});

export const { 
    useFetchRunsQuery,
     useFetchSelectRunsOptionsQuery
     } = runApi;

import { createApi } from '@reduxjs/toolkit/query/react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
    UsersWithNeoAttributesResponse,
    IUserAttributesResponse,
    IUpdateAttributesRequest,
    ISaveInitAttributesRequest,
    ISaveNewGroupRequest,
    INeoAccessGroup,
    ISetAdminAccessRequest,
    ICreateNewTeamRequest,
} from './../types';
import { axiosBaseQuery } from '../../../utils/services/rqt-axios';
import { NEO_COGNITO_API_REDUCER_KEY } from '../../consts';
import { _dispatchNotification } from '../../utilits';

const logErrors = (errorsArray: Array<{ user: string; errorReason: string }>) => {
    let message = '%cAttribute update request failed for the following users:\n';
    const styleArgs = ['color: red; font-size: larger'];

    errorsArray.forEach(({ user, errorReason }) => {
        message += `%cUser: %c${user}    %cError reason: %c${errorReason}\n`;

        styleArgs.push('color: red', 'color: white', 'color: red', 'color: white');
    });

    console.log(message, ...styleArgs);
};

const handleRequestResult = async (
    dispatch: ThunkDispatch<any, any, AnyAction>,
    queryFulfilled: any,
    successMessage?: string
) => {
    try {
        await queryFulfilled;

        if (successMessage) {
            _dispatchNotification('success', dispatch, successMessage);
        }
    } catch (error: any) {
        const errorData = JSON.parse(error?.error?.data || '');

        const isCustomError = !!errorData?.neoRequestErrors;
        const notificationMessage = isCustomError
            ? 'An error occurred during the request! See the details in the browser console.'
            : errorData || 'Request failed!';

        if (isCustomError) {
            logErrors(errorData.neoRequestErrors);
        }

        _dispatchNotification('error', dispatch, notificationMessage);
    }
};

export const neoCognitoApi = createApi({
    reducerPath: NEO_COGNITO_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['CURRENT_USER_ATTRIBUTE', 'USERS_LIST'],
    endpoints: builder => ({
        getUserAttributes: builder.query<IUserAttributesResponse, string>({
            query: (userName: string) => ({
                url: `/neo-attributes/get-user-attributes?name=${userName}`,
                method: 'GET',
            }),
            providesTags: ['CURRENT_USER_ATTRIBUTE'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(dispatch, queryFulfilled);
            },
        }),

        getUsersWithNeoAttributes: builder.query<UsersWithNeoAttributesResponse, null>({
            query: () => ({
                url: '/neo-attributes/get-users-with-neo-attributes',
                method: 'GET',
            }),
            providesTags: ['USERS_LIST'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(dispatch, queryFulfilled);
            },
        }),

        saveInitialAttributes: builder.mutation<unknown, ISaveInitAttributesRequest>({
            query: requestData => ({
                url: '/neo-attributes/save-initial-attributes',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(
                    dispatch,
                    queryFulfilled,
                    'The initial access group is successfully saved in the attributes.'
                );
            },
        }),

        saveNewAccessGroup: builder.mutation<unknown, ISaveNewGroupRequest>({
            query: requestData => ({
                url: '/neo-attributes/save-new-access-group',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE', 'USERS_LIST'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(
                    dispatch,
                    queryFulfilled,
                    'The new access group has been successfully added to the attributes.'
                );
            },
        }),

        createAccessTeam: builder.mutation<unknown, ICreateNewTeamRequest>({
            query: requestData => ({
                url: '/neo-attributes/create-access-team',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE', 'USERS_LIST'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(dispatch, queryFulfilled, 'The new access team has been successfully created.');
            },
        }),

        removeGroupFromAttributes: builder.mutation<unknown, { groupName: string; defaultGroup: INeoAccessGroup }>({
            query: requestData => ({
                url: '/neo-attributes/remove-group-from-attributes',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE', 'USERS_LIST'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(dispatch, queryFulfilled, "Group successfully removed from all users' attributes.");
            },
        }),

        updateGroupTokens: builder.mutation<unknown, Required<INeoAccessGroup>>({
            query: requestData => ({
                url: '/neo-attributes/update-group-tokens',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE', 'USERS_LIST'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(
                    dispatch,
                    queryFulfilled,

                    'Tokens have been successfully updated in the attributes of all users.'
                );
            },
        }),

        setAdminAccess: builder.mutation<unknown, ISetAdminAccessRequest>({
            query: requestData => ({
                url: '/neo-attributes/set-admin-access',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(dispatch, queryFulfilled, 'Admin access successfully registered in attributes.');
            },
        }),

        updateAttributes: builder.mutation<unknown, IUpdateAttributesRequest>({
            query: requestData => ({
                url: '/neo-attributes/update-attributes',
                method: 'PATCH',
                data: requestData,
            }),
            invalidatesTags: ['CURRENT_USER_ATTRIBUTE', 'USERS_LIST'],
            onQueryStarted(_, { dispatch, queryFulfilled }) {
                handleRequestResult(dispatch, queryFulfilled, 'Access attributes have been successfully updated.');
            },
        }),
    }),
});

export const {
    useGetUserAttributesQuery,
    useGetUsersWithNeoAttributesQuery,
    useSaveInitialAttributesMutation,
    useCreateAccessTeamMutation,
    useSaveNewAccessGroupMutation,
    useRemoveGroupFromAttributesMutation,
    useUpdateGroupTokensMutation,
    useSetAdminAccessMutation,
    useUpdateAttributesMutation,
} = neoCognitoApi;

export class Storage {
    _storages = [window.sessionStorage, window.localStorage];

    _setItem = (priority, data) =>
        Object.entries(data).forEach(([key, value]) =>
            this._storages[priority].setItem(key, value)
        );

    _getItem = (priority, key) => this._storages[priority].getItem(key);

    setInSession = (data) => this._setItem(0, data);
    setInLocal = (data) => this._setItem(1, data);

    get = (key) => this._getItem(0, key) || this._getItem(1, key);

    remove = (key) => this._storages.forEach((storage) => storage.removeItem(key));
}

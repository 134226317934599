import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { ADVANCED_REPORT_API_REDUCER_KEY } from '../consts';
import {
    IChart,
    IChartFilters,
    IHiveUsage,
    IHiveUsageFilters,
    IMobileFilters,
    IMobiles,
    IUtiltzation,
    IUtiltzationFilters,
} from './types';

export const advancedReportApi = createApi({
    reducerPath: ADVANCED_REPORT_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['AdvancedChart'],
    endpoints: (builder) => ({
        // * fetch Table Stats
        fetchTableStats: builder.mutation<IChart, Partial<IChartFilters>>({
            query: (params) => ({
                url: '/advanced-reports/getStats',
                method: 'POST',
                data: params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // // * get RUNs options
        // * fetch Table Stats
        searchDevices: builder.mutation<IMobiles, Partial<IMobileFilters>>({
            query: (param) => ({
                url: '/advanced-reports/getDevicesByRun',
                method: 'POST',
                data: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get Device By Filters
        getDeviceByFilters: builder.mutation<IMobiles, Partial<IMobileFilters>>({
            query: (param) => ({
                url: '/advanced-reports/getDeviceByFilters',
                method: 'POST',
                data: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get Devices Stats
        getDevicesStats: builder.mutation<IUtiltzation, Partial<IUtiltzationFilters>>({
            query: (param) => ({
                url: '/advanced-reports/equipmentStats',
                method: 'POST',
                data: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get Hosts Stats
        getHostsStats: builder.mutation<IUtiltzation, Partial<IUtiltzationFilters>>({
            query: (param) => ({
                url: '/advanced-reports/hostStats',
                method: 'POST',
                data: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get Devices Stats Per Day
        getDevicesStatsPerDay: builder.mutation<IUtiltzation, Partial<IUtiltzationFilters>>({
            query: (param) => ({
                url: '/advanced-reports/equipmentStatsPerDay',
                method: 'POST',
                data: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * get Hosts Stats Per Day
        getHostsStatsPerDay: builder.mutation<IUtiltzation, Partial<IUtiltzationFilters>>({
            query: (param) => ({
                url: '/advanced-reports/hostStatsPerDay',
                method: 'POST',
                data: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * fetch Hive Usage Data
        fetchHiveUsageData: builder.query<IHiveUsage, Partial<IHiveUsageFilters>>({
            query: (param) => ({
                url: `/advanced-reports/getHiveUsageStats`,
                method: 'GET',
                params: param,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),
    }),
});

export const {
    useGetDevicesStatsPerDayMutation,
    useGetHostsStatsPerDayMutation,
    useGetDeviceByFiltersMutation,
    useFetchHiveUsageDataQuery,
    useFetchTableStatsMutation,
    useGetDevicesStatsMutation,
    useSearchDevicesMutation,
    useGetHostsStatsMutation,
} = advancedReportApi;

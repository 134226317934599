import { storage } from '../services';

export const saveToken = (token, key = 'authorization', rememberMe = true) => {
    const saveToStorage = rememberMe ? storage.setInLocal : storage.setInSession;
    saveToStorage({ [key]: token });
};

export const saveTokens = (tokensObj) => {
    Object.entries(tokensObj).forEach(([key, token]) => saveToken(token, key));
};

export const getCookie = (name, cookie) => {
    const value = `; ${cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
};

export const getCookieForFront = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
        return parts.pop().split(';').shift();
    }
    return null;
};

export const removeCookie = (name) => {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

export const setCookie = (name, value) => {
    document.cookie = `${name}=${value}; path=/; expires=${new Date(
        Date.now() + 24 * 3600000
    )}; secure; SameSite=None`;
};

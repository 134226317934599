import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { USERINFO_API_REDUCER_KEY } from '../consts';
import { UserInformation, TokenParams } from './types';

export const userInfoApi = createApi({
    reducerPath: USERINFO_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['UserData'],
    endpoints: (builder) => ({
        userInfo: builder.query<UserInformation, null>({
            query: () => ({ url: `/oauth2/userinfo`, method: 'GET' }),
            providesTags: ['UserData'],
        }),

        // TODO: remove as deprecated
        // fetchUserInfo: builder.query<IUserInfo, string>({
        //     query: (token) => ({ url: `/user/info/${token}`, method: 'GET' }),
        //     providesTags: ['UserData'],
        // }),
        // updateUserInfo: builder.mutation<null, UserAttributes>({
        //     query: (attributes) => {
        //         return { url: `/user/update-attributes`, method: 'PUT', data: { attributes } };
        //     },
        //     invalidatesTags: ['UserData'],
        // }),

        generateApiToken: builder.mutation<TokenParams, string>({
            query: (authToken) => {
                const body = new FormData();
                body.append('action', 'generate');
                body.append('apiToken', authToken);
                return {
                    url: `/user/api-token`,
                    method: 'POST',
                    data: body,
                };
            },
            invalidatesTags: ['UserData'],
        }),
        updateUserMeta: builder.mutation<null, { jsonObject: Object; metaKey: string }>({
            query: ({ jsonObject, metaKey }) => {
                return {
                    url: `/user/update-usermeta`,
                    method: 'PUT',
                    data: { metaValue: jsonObject, metaKey },
                };
            },
            invalidatesTags: ['UserData'],
        }),
    }),
});

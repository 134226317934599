import { addNotification } from '../components/notications/notification.slice';
import { NotificationTypes } from '../components/notications/NotificationItem';

export function _dispatchNotification(type: NotificationTypes, dispatch: (arg0: any) => void, message: string) {
    const title = type === 'error' ? 'Request with status error: ' : 'Request result:';

    dispatch(
        addNotification({
            type,
            title,
            message,
        })
    );
}

export async function _errorHandler(dispatch: (arg0: any) => void, queryFulfilled: any) {
    try {
        await queryFulfilled;
    } catch (error: any) {
        const errorMessage = error?.error.message || error?.error.data || 'Error';

        _dispatchNotification('error', dispatch, errorMessage);
    }
}

export async function _requestResultHandler(
    dispatch: (arg0: any) => void,
    queryFulfilled: any,
    successMessage?: string
) {
    try {
        await queryFulfilled;

        if (successMessage) {
            _dispatchNotification('success', dispatch, successMessage);
        }
    } catch (error: any) {
        const errorMessage = error?.error.message || error?.error.data || 'Error';

        _dispatchNotification('error', dispatch, errorMessage);
    }
}

import React from 'react';

const Run = React.lazy(() => import('../containers/run'));
const Team = React.lazy(() => import('../containers/team'));
const Report = React.lazy(() => import('../containers/report'));
const Members = React.lazy(() => import('../containers/members'));
const Version = React.lazy(() => import('../containers/version'));
const Request = React.lazy(() => import('../containers/request'));
const Jenkins = React.lazy(() => import('../containers/jenkins/Jenkins'));
const NeoAdmin = React.lazy(() => import('../containers/neo/neoAdmin'));
const UserInfo = React.lazy(() => import('../containers/user'));
const NeoCharts = React.lazy(() => import('../containers/neo/neoCharts'));
const Artefacts = React.lazy(() => import('../containers/artefacts'));
const Maintenance = React.lazy(() => import('../containers/neo/maintenance'));
const Utilization = React.lazy(() => import('../containers/advancedReports/Utilization'));
const NeoDashboard = React.lazy(() => import('../containers/neo/neoDashboard'));
const AdvancedReportsCharts = React.lazy(() => import('../containers/advancedReports/Charts'));
const AdvancedReportsMobiles = React.lazy(() => import('../containers/advancedReports/Mobiles'));

// const Magnum = React.lazy(() => import('../containers/magnum'));
// const HiveUsage = React.lazy(() => import('../containers/advancedReports/HiveUsage'));
// const HiveStatus = React.lazy(() => import('../containers/hiveStatus'));
// const JenkinsLogs = React.lazy(() => import('../containers/jenkins/JenkinsLogs'));

export const routes = [
    {
        path: '/requests',
        exact: true,
        name: 'Requests',
        component: Request,
    },
    {
        path: '/reports',
        exact: true,
        name: 'Reports',
        component: Report,
    },
    {
        path: '/runs',
        exact: true,
        name: 'Runs',
        component: Run,
    },
    {
        path: '/info',
        exact: true,
        name: 'User info',
        component: UserInfo,
    },
    {
        path: '/team',
        exact: true,
        name: 'Team management',
        component: Team,
    },
    {
        path: '/version',
        exact: true,
        name: 'Version',
        component: Version,
    },
    {
        path: '/team/members/:id',
        exact: true,
        name: 'Members',
        component: Members,
    },
    {
        path: '/advanced-reports/charts',
        exact: true,
        name: 'Advanced Reports Charts',
        component: AdvancedReportsCharts,
    },
    {
        path: '/advanced-reports/mobiles',
        exact: true,
        name: 'Advanced Reports Mobiles',
        component: AdvancedReportsMobiles,
    },
    {
        path: '/advanced-reports/utilization',
        exact: true,
        name: 'Advanced Reports Utilization',
        component: Utilization,
    },

    //* COMMENTED 7.03.23 these components need to be upgraded
    
    // {
    //     path: '/advanced-reports/hive-usage',
    //     exact: true,
    //     name: 'Advanced Reports Hive Usage',
    //     component: HiveUsage,
    // },
    // {
    //     path: '/magnum',
    //     exact: true,
    //     name: 'Magnum',
    //     component: Magnum,
    // },
    // {
    //     path: '/hive-status',
    //     exact: true,
    //     name: 'Hive Status',
    //     component: HiveStatus,
    // },

    // {
    //     path: '/jenkins/logs',
    //     exact: true,
    //     name: 'Jenkins',
    //     component: JenkinsLogs,
    // },
    {
        path: '/artefacts',
        exact: true,
        name: 'Artefacts',
        component: Artefacts,
    },
    {
        path: '/jenkins',
        exact: true,
        name: 'Jenkins',
        component: Jenkins,
    },
    {
        path: '/neo-dashboard',
        exact: true,
        name: 'NeoDashboard',
        component: NeoDashboard,
    },
    {
        path: '/neo-dashboard/:pageTab',
        exact: true,
        name: 'NeoDashboard',
        component: NeoDashboard,
    },
    {
        path: '/neo-admin',
        exact: true,
        name: 'NeoAdmin',
        component: NeoAdmin,
    },
    {
        path: '/neo-admin/:pageTab',
        exact: true,
        name: 'NeoAdmin',
        component: NeoAdmin,
    },
    {
        path: '/maintenance',
        exact: true,
        name: 'Maintenance',
        component: Maintenance,
    },
    {
        path: ['/neo-charts', '/neo-charts/:pageTab'],
        exact: true,
        name: 'NeoCharts',
        component: NeoCharts,
    },
];

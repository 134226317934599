import { combineReducers, Reducer } from '@reduxjs/toolkit';
import { RESET_STATE_ACTION_TYPE } from './actions/resetState';
import {
    notificationReducer,
    notificationsSlice,
} from '../components/notications/notification.slice';
import { authApi } from './auth';
import { userInfoApi } from './user_info';
import { runApi } from './run/api';
import { reportApi } from './reports/api';
import { magnumApi } from './magnum/api';
import { jenkinsApi } from './jenkins';
import { requestApi } from './request/api';
import { advancedReportApi } from './advancedReports/api';
import { teamApi } from './team/api';
import { versionApi } from './version/api';
import { artefactApi } from './artefacts/api';
import { neoMainApi, neoCognitoApi } from './neo';
import {
    ADVANCED_REPORT_API_REDUCER_KEY,
    USERINFO_API_REDUCER_KEY,
    REQUEST_API_REDUCER_KEY,
    JENKINS_API_REDUCER_KEY,
    VERSION_API_REDUCER_KEY,
    MAGNUM_API_REDUCER_KEY,
    REPORT_API_REDUCER_KEY,
    TEAM_API_REDUCER_KEY,
    RUN_API_REDUCER_KEY,
    ARTEFACT_API_REDUCER_KEY,
    AUTH_API_REDUCER_KEY,
    NEO_MAIN_API_REDUCER_KEY,
    NEO_COGNITO_API_REDUCER_KEY
} from './consts';

const reducers = {
    [notificationsSlice.name]: notificationReducer,
    [AUTH_API_REDUCER_KEY]: authApi.reducer,
    [RUN_API_REDUCER_KEY]: runApi.reducer,
    [TEAM_API_REDUCER_KEY]: teamApi.reducer,
    [REPORT_API_REDUCER_KEY]: reportApi.reducer,
    [MAGNUM_API_REDUCER_KEY]: magnumApi.reducer,
    [JENKINS_API_REDUCER_KEY]: jenkinsApi.reducer,
    [REQUEST_API_REDUCER_KEY]: requestApi.reducer,
    [VERSION_API_REDUCER_KEY]: versionApi.reducer,
    [ARTEFACT_API_REDUCER_KEY]: artefactApi.reducer,
    [USERINFO_API_REDUCER_KEY]: userInfoApi.reducer,
    [ADVANCED_REPORT_API_REDUCER_KEY]: advancedReportApi.reducer,
    [NEO_MAIN_API_REDUCER_KEY]: neoMainApi.reducer,
    [NEO_COGNITO_API_REDUCER_KEY]: neoCognitoApi.reducer,
};

export const combinedReducer = combineReducers<typeof reducers>(reducers);
type RootState = ReturnType<typeof combinedReducer>;

export const rootReducer: Reducer<RootState> = (state, action) => {
    if (action.type === RESET_STATE_ACTION_TYPE) {
        state = {} as RootState;
    }

    return combinedReducer(state, action);
};

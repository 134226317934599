import React, { FC } from 'react';
import ApiRequestPreloader from '../../../../components/apiRequestPreloader/apiRequestPreloader';
import { useAuthUser } from '../../../../hooks';
import { userInfoApi } from '../../../../state/user_info';
import { ACCESS_TOKEN, USER_DATA_STORAGE_ID } from '../../../../config';
import { IUserLSInfo } from '../../../../state/neo/types';

export const UserMiddleware: FC = ({ children }) => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    const user = useAuthUser();

    userInfoApi.endpoints.userInfo.useQuery(null, {
        skip: !accessToken,
    });

    if (!user && accessToken) {
        return <ApiRequestPreloader />;
    }

    if (user && !localStorage.getItem(USER_DATA_STORAGE_ID)) {
        const userLSInfo: IUserLSInfo = {
            name: user.username,
            email: user.email,
        };

        localStorage.setItem(USER_DATA_STORAGE_ID, JSON.stringify(userLSInfo));
    }

    return children as React.ReactElement;
};

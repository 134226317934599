export const appVersion = '2.8A';

export const theme = {
    normalPrimaryColor: 'rgba(0, 103, 253, 1)',
    darkPrimaryColor: '#005DE5',
    lightPrimaryColor: 'rgba(0, 103, 253, 0.5)',

    normalSecondaryColor: 'rgba(0, 103, 253, 0.1)',
    darkSecondaryColor: 'rgba(0, 103, 253, 0.2)',
    lightSecondaryColor: 'rgba(0, 103, 253, 0.1)',

    transparentColor: 'rgba(0, 0, 0, 0)',

    whiteColor: '#FFFFFF',
    whiteClear: 'rgba(255, 255, 255, 0.9)',
    blackColor: '#173753',
    grayColor: 'rgba(23, 55, 83, 0.4)',
    lightGrayColor: 'rgba(23, 55, 83, 0.15)',
    buntingColor: 'rgba(38, 46, 62, 0.1)',
    redColor: '#FA5367',
    blueColor: '#0090ff',
    darkBlueColor: 'rgba(23, 55, 83, 0.7)',
    aquamarineColor: 'rgba(0, 162, 255, 0.753)',
    lightGreen: '#E8FBF3',
    greenColor: 'rgba(21, 210, 138, 0.7)',
    lightRedColor: '#FFDFE5',
    redErrorColor: 'rgba(255, 242, 204, 0.5)',
    boxShadowColor: 'rgba(38, 46, 62, 0.4)',

    statusPassedColor: 'rgb(90 204 90)',
    statusHasWarningsColor: 'rgb(152, 218, 152)',
    statusCrashedColor: 'rgb(248 153 153)',
    statusFailedColor: 'rgb(250 239 125)',
    statusInitialCrashedColor: 'rgb(192, 192, 192)',
    statusPassedWarningsColor: 'rgb(167 230 167)',

    statusPassedLight: 'rgba(258, 234, 258, .25)',
    statusFailedLight: 'rgba(253, 249, 210, .25)',
    statusCrashedLight: 'rgba(248, 153, 153, .25)',
    statusInitialCrashedLight: 'rgba(222, 232, 233, .25)',

    statusPassedHover: 'rgba(152, 251, 152, .5)',
    statusFailedHover: 'rgba(255, 242, 204, .5)',
    statusCrashedHover: 'rgba(240, 128, 128, .5)',
    statusSkippedHover: 'rgba(206, 212, 218, .5)',
};

export const timeZones = [
    {label: 'International Date Line West (UTC-12:00)', value: '-12:00'},
    {label: 'Midway Island, Samoa (Pacific/Midway) (UTC-11:00)', value: '-11:00'},
    {label: 'Hawaii (Pacific/Honolulu) (UTC-10:00)', value: '-10:00'},
    {label: 'Aleutian Islands (UTC-10:00)', value: '-10:00'},
    {label: 'Marquesas Islands (UTC-9:30)', value: '9:30'},
    {label: 'Alaska (US/Alaska) (UTC-9:00)', value: '-09:00'},
    {label: 'Pacific Time (US & Canada) (America/Los_Angeles) (UTC-08:00)', value: '-08:00'},
    {label: 'Tijuana, Baja California (America/Tijuana) (UTC-08:00)', value: '-08:00'},
    {label: 'Arizona (US/Arizona) (UTC-07:00)', value: '-07:00'},
    {label: 'Chihuahua, La Paz, Mazatlan (America/Chihuahua) (UTC-07:00)', value: '-07:00'},
    {label: 'Mountain Time (US & Canada) (US/Mountain) (UTC-07:00)', value: '-07:00'},
    {label: 'Central America (America/Managua) (UTC-06:00)', value: '-06:00'},
    {label: 'Central Time (US & Canada) (US/Central) (UTC-06:00)', value: '-06:00'},
    {
        label: 'Guadalajara, Mexico City, Monterrey (America/Mexico_City) (UTC-06:00)',
        value: '-06:00',
    },
    {label: 'Saskatchewan (UTC-06:00)', value: '-06:00'},
    {label: 'Bogota, Lima, Quito, Rio Branco (America/Bogota) (UTC-05:00)', value: '-05:00'},
    {label: 'Eastern Time (US & Canada) (US/Eastern) (UTC-05:00)', value: '-05:00'},
    {label: 'Indiana (East) (US/East-Indiana) (UTC-05:00)', value: '-05:00'},
    {label: 'Turks and Caicos (UTC-05:00)', value: '-05:00'},
    {label: 'Havana (UTC-05:00)', value: '-05:00'},
    {label: 'Haiti (UTC-05:00)', value: '-05:00'},
    {label: 'Atlantic Time (Canada) (UTC-04:00)', value: '-04:00'},
    {label: 'Caracas, La Paz (UTC-04:00)', value: '-04:00'},
    {label: 'Asuncion (UTC-04:00)', value: '-04:00'},
    {label: 'Cuiaba (UTC-04:00)', value: '-04:00'},
    {label: 'Georgetown, La Paz, Manaus, San Juan (UTC-04:00)', value: '-04:00'},
    {label: 'Santiago (UTC-04:00)', value: '-04:00'},
    {label: 'Newfoundland (UTC-03:30)', value: '-03:30'},
    {label: 'Brasilia (UTC-03:00)', value: '-03:00'},
    {label: 'Buenos Aires, Georgetown (UTC-03:00)', value: '-03:00'},
    {label: 'Greenland (America/Godthab) (UTC-03:00)', value: '-03:00'},
    {label: 'Montevideo (America/Montevideo) (UTC-03:00)', value: '-03:00'},
    {label: 'Cayenne, Fortaleza (UTC-03:00)', value: '-03:00'},
    {label: 'Punta Arenas (UTC-03:00)', value: '-03:00'},
    {label: 'Saint Pierre and Miquelon (UTC-03:00)', value: '-03:00'},
    {label: 'Salvador (UTC-03:00)', value: '-03:00'},
    {label: 'Mid-Atlantic (America/Noronha) (UTC-02:00)', value: '-02:00'},
    {label: 'Cape Verde Is. (UTC-01:00)', value: '-01:00'},
    {label: 'Azores (Atlantic/Azores) (UTC-01:00)', value: '-01:00'},
    {label: 'Monrovia, Reykjavik (UTC+00:00)', value: '+00:00'},
    {
        label: 'Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London (Etc/Greenwich) (UTC+00:00)',
        value: '+00:00',
    },
    {label: 'Sao Tome (UTC+00:00)', value: '+00:00'},
    {label: 'Casablanca (UTC+01:00)', value: '+01:00'},
    {label: 'Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna (UTC+01:00)', value: '+01:00'},
    {label: 'Belgrade, Bratislava, Budapest, Ljubljana, Prague (UTC+01:00)', value: '+01:00'},
    {label: 'Brussels, Copenhagen, Madrid, Paris (UTC+01:00)', value: '+01:00'},
    {label: 'Sarajevo, Skopje, Warsaw, Zagreb (UTC+01:00)', value: '+01:00'},
    {label: 'West Central Africa (UTC+01:00)', value: '+01:00'},
    {label: 'Amman (Asia/Amman) (UTC+02:00)', value: '+02:00'},
    {label: 'Athens, Bucharest, Istanbul (UTC+02:00)', value: '+02:00'},
    {label: 'Beirut (UTC+02:00)', value: '+02:00'},
    {label: 'Cairo (UTC+02:00)', value: '+02:00'},
    {label: 'Harare, Pretoria (UTC+02:00)', value: '+02:00'},
    {label: 'Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius (UTC+02:00)', value: '+02:00'},
    {label: 'Jerusalem (UTC+02:00)', value: '+02:00'},
    {label: 'Kaliningrad (UTC+02:00)', value: '+02:00'},
    {label: 'Windhoek (UTC+02:00)', value: '+02:00'},
    {label: 'Khartoum (UTC+02:00)', value: '+02:00'},
    {label: 'Tripoli (UTC+02:00)', value: '+02:00'},
    {label: 'Kuwait, Riyadh, Baghdad (UTC+03:00)', value: '+03:00'},
    {label: 'Minsk (UTC+03:00)', value: '+03:00'},
    {label: 'Moscow, St. Petersburg (UTC+03:00)', value: '+03:00'},
    {label: 'Nairobi (UTC+03:00)', value: '+03:00'},
    {label: 'Tehran (UTC+03:00)', value: '+03:30'},
    {label: 'Tbilisi (UTC+04:00)', value: '+04:00'},
    {label: 'Abu Dhabi, Muscat (UTC+04:00)', value: '+04:00'},
    {label: 'Izhevsk, Samara (UTC+04:00)', value: '+04:00'},
    {label: 'Port Louis (UTC+04:00)', value: '+04:00'},
    {label: 'Saratov (UTC+04:00)', value: '+04:00'},
    {label: 'Baku (UTC+04:00)', value: '+04:00'},
    {label: 'Yerevan (UTC+04:00)', value: '+04:00'},
    {label: 'Volgograd (UTC+04:00)', value: '+04:00'},
    {label: 'Kabul (UTC+04:30)', value: '+04:30'},
    {label: 'Ekaterinburg (UTC+05:00)', value: '+05:00'},
    {label: 'Islamabad, Karachi, Tashkent (UTC+05:00)', value: '+05:00'},
    {label: 'Chennai, Kolkata, Mumbai, New Delhi (UTC+05:30)', value: '+05:30'},
    {label: 'Sri Jayawardenapura (UTC+05:30)', value: '+05:30'},
    {label: 'Kathmandu (UTC+05:45)', value: '+05:45'},
    {label: 'Astana, Dhaka (UTC+06:00)', value: '+06:00'},
    {label: 'Omsk (UTC+06:00)', value: '+06:00'},
    {label: 'Almaty (UTC+06:00)', value: '+06:00'},
    {label: 'Yangon (Rangoon) (UTC+06:30)', value: '+06:30'},
    {label: 'Bangkok, Hanoi, Jakarta (UTC+07:00)', value: '+07:00'},
    {label: 'Barnaul, Gorno-Altaysk (UTC+07:00)', value: '+07:00'},
    {label: 'Hovd (UTC+07:00)', value: '+07:00'},
    {label: 'Krasnoyarsk, Novosibirsk, Tomsk (Asia/Krasnoyarsk)', value: '+07:00'},
    {label: 'Beijing, Chongqing, Hong Kong, Urumqi (UTC+08:00)', value: '+08:00'},
    {label: 'Irkutsk, Ulaan Bataar (UTC+08:00)', value: '+08:00'},
    {label: 'Kuala Lumpur, Singapore (UTC+08:00)', value: '+08:00'},
    {label: 'Perth (UTC+08:00)', value: '+08:00'},
    {label: 'Taipei (UTC+08:00)', value: '+08:00'},
    {label: 'Eucla  (UTC+08:45)', value: '+08:45'},
    {label: 'Osaka, Sapporo, Tokyo, Chita (UTC+09:00) ', value: '+09:00'},
    {label: 'Seoul (UTC+09:00)', value: '+09:00'},
    {label: 'Yakutsk (UTC+09:00)', value: '+09:00'},
    {label: 'Adelaide (UTC+09:00)', value: '+09:30'},
    {label: 'Darwin (UTC+09:30)', value: '+09:30'},
    {label: 'Brisbane (UTC+10:00)', value: '+10:00'},
    {label: 'Canberra, Melbourne, Sydney (UTC+10:00) ', value: '+10:00'},
    {label: 'Guam, Port Moresby (UTC+10:00)', value: '+10:00'},
    {label: 'Hobart (UTC+10:00)', value: '+10:00'},
    {label: 'Vladivostok (UTC+10:00)', value: '+10:00'},
    {label: 'Lord Howe Island  (UTC+10:30)', value: '+10:30'},
    {label: 'Magadan, Solomon Is., New Caledonia (UTC+11:00)', value: '+11:00'},
    {label: 'Bougainville Island (UTC+11:00)', value: '+11:00'},
    {label: 'Chokurdakh  (UTC+11:00)', value: '+11:00'},
    {label: 'Norfolk Island (UTC+11:00)', value: '+11:00'},
    {label: 'Sakhalin (UTC+11:00)', value: '+11:00'},
    {label: 'Auckland, Wellington, Anadyr (UTC+12:00)', value: '+12:00'},
    {label: 'Fiji, Kamchatka, Marshall Is. (UTC+12:00)', value: '+12:00'},
    {label: 'Chatham Islands. (UTC+12:45)', value: '+12:45'},
    {label: "Nuku'alofa (UTC+13:00)", value: '+13:00'},
    {label: 'Samoa  (UTC+13:00)', value: '+13:00'},
    {label: 'Kiritimati Island  (UTC+14:00)', value: '+14:00'},
];

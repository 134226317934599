import React from 'react';
import { Provider } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { Routes } from './routes/Routes';
import { store } from './state/store';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Auth } from './containers/auth/Auth';
import { UserMiddleware } from './containers/auth/components/UserMiddleware/UserMiddleware';
import './index.scss';

const history = createBrowserHistory({ window });

const App = () => {
    return (
        <Provider store={store}>
            <Router history={history}>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <UserMiddleware>
                        <Auth />
                        <Routes />
                    </UserMiddleware>
                </QueryParamProvider>
            </Router>
        </Provider>
    );
};

export default App;

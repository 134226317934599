import { ORDER, RESOURCE, TASK } from '../../consts/neoConstants';

export const orderStateToStr = (state) => {
    switch (state) {
        case ORDER.UNRESOLVED:
            return 'UNRESOLVED';
        case ORDER.POSTPONED:
            return 'POSTPONED';
        case ORDER.IN_EXECUTION:
            return 'IN EXECUTION';
        case ORDER.DELETED:
            return 'DELETED';
        case ORDER.NO_ORDER_ID:
            return 'NO ORDER ID';
        default:
            return '-';
    }
};

export const orderStateToNum = (stateStr) => {
    switch (stateStr) {
        case 'UNRESOLVED':
            return ORDER.UNRESOLVED;
        case 'POSTPONED':
            return ORDER.POSTPONED;
        case 'IN EXECUTION':
            return ORDER.IN_EXECUTION;
        case 'NO ORDER ID':
            return ORDER.NO_ORDER_ID;
        case 'DELETED':
            return ORDER.DELETED;
        default:
            return null;
    }
};

export const resStateToNum = (stateStr) => {
    switch (stateStr) {
        case 'IDLE':
            return RESOURCE.IDLE;
        case 'ALOCATED':
            return RESOURCE.ALOCATED;
        case 'REMOVED':
            return RESOURCE.REMOVED;
        case 'OUT OF SERVICE':
            return RESOURCE.OUT_OF_SERVICE;
        case 'READY TO DELIVER':
            return RESOURCE.READY_TO_DELIVER;
        case 'DELIVERED':
            return RESOURCE.DELIVERED;
        case 'UNDER RESTORE':
            return RESOURCE.UNDER_RESTORE;
        case 'UPDATING':
            return RESOURCE.UPDATING;
        case 'MAINTENANCE':
            return RESOURCE.MAINTENANCE;
        default:
            return null;
    }
};

export const resStateToStr = (state) => {
    switch (state) {
        case RESOURCE.IDLE:
            return 'IDLE';
        case RESOURCE.ALOCATED:
            return 'ALOCATED';
        case RESOURCE.REMOVED:
            return 'REMOVED';
        case RESOURCE.OUT_OF_SERVICE:
            return 'OUT OF SERVICE';
        case RESOURCE.READY_TO_DELIVER:
            return 'READY TO DELIVER';
        case RESOURCE.DELIVERED:
            return 'DELIVERED';
        case RESOURCE.UNDER_RESTORE:
            return 'UNDER RESTORE';
        case RESOURCE.UPDATING:
            return 'UPDATING';
        case RESOURCE.MAINTENANCE:
            return 'MAINTENANCE';
        default:
            return '-';
    }
};

export const taskStateToStr = (state) => {
    switch (state) {
        case TASK.UNRESOLVED:
            return 'UNRESOLVED';
        case TASK.POSTPONED:
            return 'POSTPONED';
        case TASK.IN_EXECUTION:
            return 'IN EXECUTION';
        case TASK.DELETED:
            return 'DELETED';
        default:
            return '-';
    }
};

export const stateToSelectOptions = (state) => {
    const options = [];

    for (const [key, value] of Object.entries(state)) {
        options.push({ label: key.split('_').join(' '), value: value });
    }

    return options;
};

import React from 'react';
import { Button, Modal } from 'react-bootstrap';

const ModalCustom = ({
    title = 'Title',
    textBody = 'Text',
    canselText = 'Cancel',
    submitText = 'Ok',
    onClose,
    isShown,
    onSubmit,
}) => {
    return (
        <Modal show={isShown} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>

            <Modal.Body>{textBody}</Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {canselText}
                </Button>
                <Button variant="primary" onClick={onSubmit}>
                    {submitText}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ModalCustom;

import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import { appVersion } from '../../consts';
import { ACCESS_TOKEN } from '../../config';
import './style.scss';

export const Header = () => {
    return (
        <header className="Header">
            <Navbar bg="dark" variant="dark" expand="lg" collapseOnSelect>
                <Container fluid>
                    <Navbar.Brand>Data Lake Version {appVersion}</Navbar.Brand>

                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <div className="user">
                                <NavDropdown title={'Settings'} id="username">
                                    <NavDropdown.Item as={Link} to="/info">
                                        <i className="fas fa-info-circle"></i> Info
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/team">
                                        <i className="fas fa-users" /> Team
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/version">
                                        <i className="fas fa-tools" /> Version
                                    </NavDropdown.Item>
                                    <NavDropdown.Item
                                        onClick={() => {
                                            localStorage.removeItem(ACCESS_TOKEN);
                                        }}
                                        href={`${process.env.REACT_APP_COGNITO_DOMAIN?.replace(
                                            'login',
                                            'logout'
                                        )}&redirect_uri=${process.env.REACT_APP_COGNITO_REDIRECT}`}
                                    >
                                        <i className="fas fa-sign-out-alt" /> Logout
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

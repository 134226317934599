// //////// NEO states //////////
const ORDER = {
    UNRESOLVED: 0, // initial order state
    POSTPONED: 1, // all suitable resources are allocated
    IN_EXECUTION: 2, // order is in execution
    NO_ORDER_ID: null, // resource is not ordered
    DELETED: 3, // order is deleted
};

const TASK = {
    UNRESOLVED: 0, // initial order state
    POSTPONED: 1, // all suitable resources are allocated
    IN_EXECUTION: 2, // order is in execution
    DELETED: 3,
};

const RESOURCE = {
    IDLE: 0, // resource is ready for use
    ALOCATED: 1, // resource is allocated for user
    REMOVED: 2, // resource is deleted
    OUT_OF_SERVICE: 3, // resource is out of service
    READY_TO_DELIVER: 4,
    DELIVERED: 5, // resource was verified by the provider
    UNDER_RESTORE: 6, // resource need to restore on the provider side
    UPDATING: 7, // resource is in process of updating
    MAINTENANCE: 8, // resource is in process of maintenance
};

// Main log events, used for charts building
const LOG_EVENTS = {
    RESERVATION: 1, // resource reservation
    UNRESERVATION: 2, // resource unreservation
};

// //////// Maintenance //////////
const MAINTENANCE_TREE_STATES = {
    IDLE: 'idle',
    MAINTENANCE: 'maintenance',
    SCHEDULED_TO_MAINTENANCE: 'scheduled to maintenance',
    ANOTHER: 'another',
};

const MAINTENANCE_TREE_TYPES = {
    STEP: 'step',
    HIVE: 'hive',
    HOST: 'host',
    RF_BOX: 'rf box',
    RESOURCE: 'resource',
};

// //////// User management //////////
const PROVIDER_ACCESS_TYPES = {
    1: 'Admin',
    2: 'User',
};

const DEFAULT_ACCESS_GROUP = 'Step users'
const DEFAULT_TEAM = 'Step users'
const NEO_ACCESS_ATTRIBUTE_NAME = 'custom:neoAccessGroups'
const NEO_TEAMS_ATTRIBUTE_NAME = 'custom:neoAccessTeams'

export {
    ORDER,
    TASK,
    RESOURCE,
    LOG_EVENTS,
    MAINTENANCE_TREE_STATES,
    MAINTENANCE_TREE_TYPES,
    PROVIDER_ACCESS_TYPES,
    DEFAULT_ACCESS_GROUP,
    DEFAULT_TEAM,
    NEO_ACCESS_ATTRIBUTE_NAME,
    NEO_TEAMS_ATTRIBUTE_NAME
}

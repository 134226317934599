const securePath = process.env.REACT_APP_SECURITY_ROUTE_PATH || 'secret';

export const loginPath = `/login/${securePath}`;
export const signupPath = `/signup/${securePath}`;
export const confirmPath = `/confirm-email/${securePath}`;
export const confirmPasswordPath = `/confirm-password/${securePath}`;
export const forgetPasswordPath = `/forget-password/${securePath}`;
export const changePasswordPath = `/change-password/${securePath}`;

export const apiUrl = process.env.REACT_APP_API_URL || 'http://localhost:3000/api/v1';
export const neoApiUrl = process.env.REACT_APP_NEO_HTTP_API_URL || 'http://localhost:3005';

export const secureLoginPath = `/login/${process.env.REACT_APP_SECURITY_ROUTE_PATH}`;

export const ACCESS_TOKEN = 'DL:accessToken';
export const USER_DATA_STORAGE_ID = 'userData';
export const NEO_ACCESSES_STORAGE_ID = 'neoAccessAttributes'

export const testUserCredentials = {
    username: 'nazar.panchenko@globallogic.com',
    password: 'Nazar$Panchenko89',
};

export const testQueryParams = {
    limit: 25,
    page: 1,
};

export const requestFilterStatuses = {
    '': 'Select status',
    0: 'Done',
    1: 'Error',
};

export const reportFilterStatuses = {
    '': 'Select run result',
    0: 'Passed',
    1: 'Failed',
    2: 'Crashed',
    3: 'Initial_crash',
    4: 'Passed_has_warnings',
    5: 'Failed_ensure',
};

export const reportFilterDortByUserId = {
    0: 'All',
    1: 'By user',
};

export const requestFilterLimit = {
    25: '25',
    50: '50',
    100: '100',
};

export const reportFilterLimit = {
    25: '25',
    50: '50',
    100: '100',
};

export const jenkinsFilterLimit = {
    10: '10',
    15: '15',
    20: '20',
};
import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { REQUEST_API_REDUCER_KEY } from '../consts';
import { IRequestFilters, IRequst } from './types';

export const requestApi = createApi({
    reducerPath: REQUEST_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Request'],
    endpoints: (builder) => ({
        // * fetch all requests with filters
        fetchRequests: builder.query<IRequst, Partial<IRequestFilters>>({
            query: (params: any) => ({
                url: '/requests',
                method: 'GET',
                params: params,
            }),
            providesTags: ['Request'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * create new request
        createRequest: builder.mutation<IRequst, Partial<IRequestFilters>>({
            query: (data: any) => ({
                url: `/requests/runs/dash`,
                method: 'POST',
                data: data,
            }),
            invalidatesTags: ['Request'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),
    }),
});

export const { useFetchRequestsQuery, useCreateRequestMutation } = requestApi;

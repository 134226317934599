import { Suspense } from 'react';
import { Switch } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { Header } from '../header/Header';
import Sidebar from '../../components/sidebar/Sidebar';
import { routes } from '../../routes/routesList';
import AuthenticatedRoute from '../auth/components/AuthenticatedRoute/AuthenticatedRoute';
import { Notifications } from '../../components/notications/Notifications';
import './style.scss';

export const Main = () => {
    return (
        <div className="App">
            <Notifications />
            <Header />
            <main className="main">
                <div className="HomeScreen">
                    <Sidebar />
                    <Container fluid style={{ width: '90%' }}>
                        <Suspense
                            fallback={<div className="align-middle text-center">Loading...</div>}
                        >
                            <Switch>
                                {routes.map((route, index) => {
                                    return (
                                        <AuthenticatedRoute
                                            key={index}
                                            exact={route.exact}
                                            path={route.path}
                                        >
                                            <route.component />
                                        </AuthenticatedRoute>
                                    );
                                })}
                            </Switch>
                        </Suspense>
                    </Container>
                </div>
            </main>
        </div>
    );
};

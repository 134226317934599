/* -----------------------------------------------------------------------------
*             Hook to get select-options from the RUNs data
------------------------------------------------------------------------------
*/
import { useCallback, useState } from 'react';
import { createOptions } from '../containers/run/utils';

export const useRunOptions = (data = []) => {
    const [runsOptions, setOptions] = useState([]);

    const  setRunsOptions = useCallback((data) =>
         setOptions((_prev) => {
            return createOptions(data)
        }), []);

    return [runsOptions, setRunsOptions]

};



import { theme } from "../../consts";

export const getColor = (result) => {
    if (result === 'PASSED') {
        return theme.statusPassedColor;
    }
    if (result === 'CRASHED') {
        return theme.statusCrashedColor;
    }
    if (result === 'FAILED') {
        return theme.statusFailedColor;
    }
    if (result === 'INITIAL_CRASH') {
        return theme.statusInitialCrashedColor;
    }
    return theme.redErrorColor;
};
export function compareValues(key, isASC) {
    let comparison = 0;
    let varA;
    let varB;

    return function innerSort(a, b) {
        varA = typeof a[key] === 'string' ? a[key].toLowerCase() : a[key];
        varB = typeof b[key] === 'string' ? b[key].toLowerCase() : b[key];
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }

        return isASC ? comparison : comparison * -1;
    };
}

/* 
*param: arr is {array} to be sorted
*param: key is {string} or {array} is an element of array we should compare
*param: isASC is {boolean} type of sorting (DESC or ASC)

*/
export function quickSort(arr, key, isASC) {
    if (arr.length <= 1) return arr;
    const pivot = arr[0];

    const left = [];
    const right = [];
    for (let i = 1; i < arr.length; i++) {
        let a = arr[i];
        let b = pivot;
        if (Array.isArray(key)) {
            key.forEach((el) => {
                a = a[el];
                b = b[el];
            });
        } else {
            a = a[key];
            b = b[key];
        }
        a = typeof a === 'string' ? a.toLowerCase() : a;
        b = typeof b === 'string' ? b.toLowerCase() : b;

        if (isASC) {
            a < b ? left.push(arr[i]) : right.push(arr[i]);
        } else {
            a > b ? left.push(arr[i]) : right.push(arr[i]);
        }
    }
    return quickSort(left, key, isASC).concat(pivot, quickSort(right, key, isASC));
}

import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { ITeam, ITeanFilters } from './types';
import { TEAM_API_REDUCER_KEY } from '../consts';

export const teamApi = createApi({
    reducerPath: TEAM_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Teams', 'Members'],
    endpoints: (builder) => ({

        // * get All Teams
        getAllTeams: builder.query<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: '/team',
                method: 'GET',
                params: params
            }),
            providesTags: ['Teams'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // *  update Team Info
        updateTeamInfo: builder.mutation<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: '/team/update',
                method: 'PUT',
                data: params,
            }),
            invalidatesTags: ['Teams'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * create new Team
        createTeam: builder.mutation<ITeam, Partial<ITeanFilters>>({
            query: (data: any) => ({
                url: `/team`,
                method: 'POST',
                data: data,
            }),
            invalidatesTags: ['Teams'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),


        // * create new Team
        deleteTeam: builder.mutation<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: 'team/delete',
                method: 'DELETE',
                params: params,
            }),
            invalidatesTags: ['Teams'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),


        // * get All Members
        getAllMembers: builder.query<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: '/team/members',
                method: 'GET',
                params: params
            }),
            providesTags: ['Members'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * add Member To the Team
        addMemberToTeam: builder.mutation<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: '/team/members',
                method: 'POST',
                data: params,
            }),
            invalidatesTags: ['Members'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // * delete Member From the Team
        deleteMember: builder.mutation<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: '/team/members',
                method: 'DELETE',
                params: params,
            }),
            invalidatesTags: ['Members'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // TODO replace in the USER API
        // * update User Group
        updateUserGroup: builder.mutation<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: `/user/update-user_group`,
                method: 'PUT',
                data: params,
            }),
            invalidatesTags: ['Members'],
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // TODO replace in the USER API
        // * get All Members
        getUserByFilter: builder.query<ITeam, Partial<ITeanFilters>>({
            query: (params: any) => ({
                url: '/user',
                method: 'GET',
                params: params
            }),
            async onQueryStarted(_: any, { dispatch, queryFulfilled }: any) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

    }),
});

export const {
    useGetAllTeamsQuery,
    useGetAllMembersQuery,
    useCreateTeamMutation,
    useDeleteTeamMutation,
    useDeleteMemberMutation,
    useGetUserByFilterQuery,
    useUpdateTeamInfoMutation,
    useAddMemberToTeamMutation,
    useUpdateUserGroupMutation,
} = teamApi;

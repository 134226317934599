import { FC } from 'react';
import { INotificationItem, NotificationItem } from './NotificationItem';
import { useTypedSelector, RootState } from '../../state/store';

export const Notifications: FC = () => {
    const { notifications } = useTypedSelector((state: RootState) => state.notifications);
    return (
        <div
            style={{
                top: 65,
                right: 25,
                zIndex: 10000, //More than popup and loader z-index
                position: 'absolute',
            }}
        >
            {notifications.map((notification: INotificationItem) => {
                return (
                    <NotificationItem
                        key={notification.id}
                        notification={notification}
                    ></NotificationItem>
                );
            })}
        </div>
    );
};

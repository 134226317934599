import { Redirect, Switch } from 'react-router-dom';
import { Main } from '../containers/main/Main';
import AuthenticatedRoute from '../containers/auth/components/AuthenticatedRoute/AuthenticatedRoute';

export const Routes = () => {
    return (
        <Switch>
            <AuthenticatedRoute exact path={'/'}>
                <Redirect to="/main" />
            </AuthenticatedRoute>
            <Main />
        </Switch>
    );
};

export const RUN_API_REDUCER_KEY = 'runApi';
export const TEAM_API_REDUCER_KEY = 'teamApi';
export const REPORT_API_REDUCER_KEY = 'reportApi';
export const MAGNUM_API_REDUCER_KEY = 'magnumApi';
export const REQUEST_API_REDUCER_KEY = 'requestApi';
export const JENKINS_API_REDUCER_KEY = 'jenkinsApi';
export const VERSION_API_REDUCER_KEY = 'versionApi';
export const ARTEFACT_API_REDUCER_KEY = 'artefactApi';
export const USERINFO_API_REDUCER_KEY = 'userInfoApi';
export const AUTH_API_REDUCER_KEY = 'authApi';
export const ADVANCED_REPORT_API_REDUCER_KEY = 'advancedReportApi';
export const NEO_COGNITO_API_REDUCER_KEY = 'neoCognitoApi';
export const NEO_MAIN_API_REDUCER_KEY = 'neoMainApi';

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthResponse } from './types';
import { apiUrl } from '../../config';

export const AUTH_API_REDUCER_KEY = 'authApi';
export const authApi = createApi({
    reducerPath: AUTH_API_REDUCER_KEY,
    baseQuery: fetchBaseQuery({
        baseUrl: `${apiUrl}`,
    }),
    endpoints: (builder) => ({
        getAccessToken: builder.query<AuthResponse, string>({
            query: (code) => {
                return {
                    url: `oauth2/token/${code}`,
                    method: 'GET',
                };
            },
        }),
    }),
});

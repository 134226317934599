export const getAllKeysFromListOfObjects = (dataArr) =>
    dataArr.reduce((acc, curr) => {
        const allItemKeys = Object.keys(curr);
        const newAcc = [];
        allItemKeys.forEach((key) => {
            if (acc.indexOf(key) === -1) {
                newAcc.push(key);
            }
        });
        return newAcc;
    }, []);

export const formatBytes = (bytes, decimals = 2) => {
    if (isNaN(bytes) || bytes === null) return '';

    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const parseJsonStringPretty = (str) => JSON.stringify(JSON.parse(str), null, '\t');

export const selectColorForRequestStatus = (status) => {
    let statusClassName;
    switch (status) {
        case 'Query done':
            statusClassName = 'run-result success';
            break;
        case 'STARTED':
            statusClassName = 'run-result in-progress';
            break;
        case 'ERROR':
            statusClassName = 'run-result error';
            break;
        default:
            statusClassName = 'run-result';
    }
    return statusClassName;
};

export const getRunPercentage = (uploadedReports, expectedReports) => {
    let ratio; // range [0, 1];
    if (uploadedReports === 0) {
        ratio = 0;
    } else if (expectedReports === 0) {
        ratio = 1;
    } else if (uploadedReports >= expectedReports) {
        ratio = 1;
    } else {
        ratio = uploadedReports / expectedReports;
    }

    return Math.floor(ratio * 100); // range [0, 100];
};

export const convertTimestampToDate = (timestamp) => {
    const date = new Date(timestamp);
    return `${date.getDate()}/${
        date.getMonth() + 1
    }/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

export const secondsToMinSec = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const restSeconds = seconds % 60;
    return `${minutes} minutes ${restSeconds} seconds`;
};

export const NOW = new Date().getTime();
export const HALF_HOUR = 1800000;

import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { apiUrl, neoApiUrl, ACCESS_TOKEN, USER_DATA_STORAGE_ID, NEO_ACCESSES_STORAGE_ID } from '../../config';
import { INeoUserAccessAttributes, INeoHeaders, IUserLSInfo } from '../../state/neo/types';

const rtkAxios = axios.create({
    baseURL: apiUrl,
});

const neoRtkAxios = axios.create({
    baseURL: neoApiUrl,
});

interface ClientError extends AxiosError {
    config: object;
    data: {
        message?: string;
        status: number | string;
    };
    status: number | string;
    statusText: string;
}

export const axiosBaseQuery =
    (
        apiHost?: string
    ): BaseQueryFn<
        {
            url: string;
            method: AxiosRequestConfig['method'];
            data?: AxiosRequestConfig['data'];
            params?: AxiosRequestConfig['params'];
            headers?: AxiosRequestConfig['headers'];
            responseType?: AxiosRequestConfig['responseType'];
        },
        unknown,
        unknown
    > =>
    async ({ url, method, data, params, headers, responseType }) => {
        try {
            let currentApiClient = rtkAxios;
            const accessToken = localStorage.getItem(ACCESS_TOKEN);

            if (apiHost === 'neo') {
                currentApiClient = neoRtkAxios;

                const userData = JSON.parse(localStorage.getItem(USER_DATA_STORAGE_ID)!) as IUserLSInfo;
                const neoAccesses = localStorage.getItem(NEO_ACCESSES_STORAGE_ID);

                const neoHeaders: INeoHeaders = { 'User-Email': userData.email};

                if (neoAccesses) {
                    const parsedAccesses = JSON.parse(neoAccesses) as INeoUserAccessAttributes;

                    neoHeaders['Neo-Access-Groups'] = JSON.stringify(parsedAccesses.accessGroups);
                }

                headers = { ...headers, ...neoHeaders };
            }

            const result = await currentApiClient({
                url,
                method,
                data,
                params,
                headers: { ...headers, Authorization: accessToken },
                responseType,
            });

            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as ClientError;

            return {
                error: {
                    status: err.response?.status || err.status || err.data.status,
                    data: err.response?.data.message || err.message || err.data.message,
                },
            };
        }
    };

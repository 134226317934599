import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { NEO_MAIN_API_REDUCER_KEY } from '../consts';
import { _requestResultHandler } from '../utilits';
import { INeoAccessGroup } from './types';

export const neoMainApi = createApi({
    reducerPath: NEO_MAIN_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery('neo'),
    refetchOnMountOrArgChange: true,
    endpoints: builder => ({
        getDefaultAccessGroup: builder.query<INeoAccessGroup, null>({
            query: () => ({
                url: '/get_default_access_group',
                method: 'GET',
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _requestResultHandler(dispatch, queryFulfilled);
            },
        }),
    }),
});

export const { useGetDefaultAccessGroupQuery } = neoMainApi;
import React, { useEffect } from 'react';
import { Redirect } from 'react-router';
import { StringParam, useQueryParam } from 'use-query-params';
import { authApi } from '../../../../state/auth';
import { ApiRequestPreloader } from '../../../../components';
import { useTypedDispatch } from '../../../../state/store';
import { userInfoApi } from '../../../../state/user_info';
import { ACCESS_TOKEN } from '../../../../config';
import { setCookie } from '../../../../utils/helpers';

const OAuth = () => {
    const dispatch = useTypedDispatch();
    const [code] = useQueryParam('code', StringParam);
    const accessTokenQueryResult = authApi.endpoints.getAccessToken.useQuery(code!, {
        skip: !code,
    });
    const { data } = accessTokenQueryResult;
    const accessToken = data?.access_token;

    useEffect(() => {
        if (!accessToken) return;

        localStorage.setItem(ACCESS_TOKEN, accessToken);
        setCookie('token', accessToken);
        dispatch(userInfoApi.endpoints.userInfo.initiate(null));
    }, [accessToken, dispatch]);

    if (!code) {
        return <Redirect to="/login" />;
    }
    if (data?.error) {
        return <>Error: {data.error}</>;
    }

    return <ApiRequestPreloader />;
};

export default OAuth;

import { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListGroup, Button, DropdownButton, Dropdown } from 'react-bootstrap';

import './Sidebar.scss';

const Sidebar = () => {
    const [isHide, setIsHide] = useState(false);

    const handleTogle = () => {
        setIsHide(!isHide);
    };

    const clazz = isHide ? 'Sidebar hidden' : 'Sidebar';
    const arrowPosition = isHide ? 'fas fa-arrow-right' : 'fas fa-arrow-left';

    return (
        <div className={clazz}>
            <div className="toggle">
                <Button variant="outline-secondary" size="sm" block onClick={handleTogle}>
                    <i className={`${arrowPosition}`} />
                </Button>
            </div>

            <ListGroup variant="flush">
                <ListGroup.Item>
                    <Link to="/reports">
                        <i className="far fa-sticky-note" /> Reports
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/requests">
                        <i className="fas fa-home" /> Requests
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/runs">
                        <i className="fas fa-shopping-cart" /> Runs
                    </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                    <Link to="/advanced-reports/charts">
                        <i className="fa fa-search" aria-hidden="true" /> Advanced Reports
                    </Link>
                </ListGroup.Item>

                <DropdownButton
                    title={
                        <div>
                            <i className="fas fa-project-diagram" /> NEO
                        </div>
                    }
                    variant="light"
                    drop="right"
                >
                    <ListGroup.Item as={Dropdown.Item}>
                        <Link to="/neo-dashboard/resources">
                            <i className="fas fa-columns" /> Dashboard
                        </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as={Dropdown.Item}>
                        <Link to="/neo-admin/resource-providers">
                            <i className="fas fa-user" /> Admin
                        </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as={Dropdown.Item}>
                        <Link to="/maintenance">
                            <i className="fas fa-wrench" /> Maintenance
                        </Link>
                    </ListGroup.Item>
                    <ListGroup.Item as={Dropdown.Item}>
                        <Link to="/neo-charts/resources">
                            <i className="fas fa-chart-bar" /> Charts
                        </Link>
                    </ListGroup.Item>
                </DropdownButton>

                <ListGroup.Item>
                    <Link to="/artefacts">
                        <i className="fa fa-upload" /> Artefacts
                    </Link>
                </ListGroup.Item>

                {/*  COMMENTED 7.03.23 these components need to be upgraded */}
                {/* <ListGroup.Item>
                    <Link to="/hive-status">
                        <i className="far fa-file-alt" /> Hive Status
                    </Link>
                </ListGroup.Item> */}
                {/* <ListGroup.Item>
                    <Link to="/magnum">
                        <i className="fas fa-mobile" /> Magnum
                    </Link>
                </ListGroup.Item> */}
                {/* <ListGroup.Item>
                    <Link to="/jenkins">
                        <i className="fa fa-cloud" /> Jenkins Integration
                    </Link>
                </ListGroup.Item> */} 
                
            </ListGroup>
        </div>
    );
};

export default Sidebar;

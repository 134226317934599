import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { ARTEFACT_API_REDUCER_KEY } from '../consts';
import { IArtefact, IArtefactFilters, } from './types';

export const artefactApi = createApi({
    reducerPath: ARTEFACT_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Artefacts'],

    endpoints: (builder) => ({
        
        // * fetch Artefacts List
        fetchArtefactsList: builder.query<IArtefact, Partial<IArtefactFilters>>({
            query: (params) => ({
                url: '/artefacts-api/front/list',
                method: 'GET',
                params: params,
            }),
            providesTags: ['Artefacts'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

    }),
});

export const {  
    useFetchArtefactsListQuery
} = artefactApi;

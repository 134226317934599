import { isRejectedWithValue, Middleware } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { resetStateAction } from './actions/resetState';
import { authApi } from './auth';
import { neoMainApi, neoCognitoApi } from './neo';
import { runApi } from './run/api';
import { teamApi } from './team/api';
import { reportApi } from './reports/api';
import { magnumApi } from './magnum/api';
import { jenkinsApi } from './jenkins';
import { requestApi } from './request/api';
import { versionApi } from './version/api';
import { userInfoApi } from './user_info';
import { artefactApi } from './artefacts/api';
import { advancedReportApi } from './advancedReports/api';
import { addNotification } from '../components/notications/notification.slice';
import { ACCESS_TOKEN, USER_DATA_STORAGE_ID, NEO_ACCESSES_STORAGE_ID } from '../config';

export const history = createBrowserHistory();

const unauthenticatedMiddleware: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action) && action.payload.status === 401) {
            localStorage.removeItem(ACCESS_TOKEN);
            localStorage.removeItem(USER_DATA_STORAGE_ID);
            localStorage.removeItem(NEO_ACCESSES_STORAGE_ID);
            
            dispatch(resetStateAction());
        }

        return next(action);
    };

export const rtkQueryErrorLogger: Middleware =
    ({ dispatch }) =>
    (next) =>
    (action) => {
        if (isRejectedWithValue(action) && action.payload.status >= 400) {
            dispatch(
                addNotification({
                    type: 'error',
                    title: 'Error: ',
                    message: action.payload.data.message,
                })
            );
        }

        return next(action);
    };

const createMiddlewares = () => [
    unauthenticatedMiddleware,
    authApi.middleware,
    userInfoApi.middleware,
    artefactApi.middleware,
    jenkinsApi.middleware,
    versionApi.middleware,
    requestApi.middleware,
    magnumApi.middleware,
    reportApi.middleware,
    teamApi.middleware,
    runApi.middleware,
    neoMainApi.middleware,
    neoCognitoApi.middleware,
    advancedReportApi.middleware,
];

export default createMiddlewares;

import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { IVersion } from './types';
import { VERSION_API_REDUCER_KEY } from '../consts';

export const versionApi = createApi({
    reducerPath: VERSION_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Version'],
    endpoints: (builder) => ({

        // * get List Component Version
        getListComponentVersion: builder.query<IVersion, null>({
            query: () => ({
                url: '/version',
                method: 'GET',
            }),
            providesTags: ['Version'],
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }), 
    }),
});

export const {
useGetListComponentVersionQuery,
} = versionApi;

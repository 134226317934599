import { createApi } from '@reduxjs/toolkit/query/react';
import {
    JenkinsResponse,
    JenkinsSearchArgs,
    JenkinsConfigParams,
    JenkinsLogsQueryParams,
    JenkinsBuildsQueryParams,
    JenkinsBuild,
} from './types';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { JENKINS_API_REDUCER_KEY } from '../consts';

export const jenkinsApi = createApi({
    reducerPath: JENKINS_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['JenkinsSearch'],
    endpoints: (builder) => ({
        searchJenkinsJobs: builder.query<JenkinsResponse, Partial<JenkinsSearchArgs>>({
            query: (args) => ({ url: '/jenkins/jobs', method: 'GET', params: args }),
            providesTags: ['JenkinsSearch'],
        }),
        triggerJobWithParams: builder.mutation<{ error?: string }, unknown>({
            query: (body) => ({
                url: '/jenkins/job/buildWithParameters',
                method: 'POST',
                data: body,
            }),
        }),
        getJobConfigs: builder.query<
            JenkinsConfigParams[],
            { jobname: string; redisChannel: string }
        >({
            query: ({ jobname, redisChannel }) => ({
                url: `/jenkins/configs/${jobname}`,
                method: 'GET',
                params: { redisChannel },
            }),
        }),
        getJobLogs: builder.query<
            { logs: string },
            { params: JenkinsLogsQueryParams; redisChannel: string }
        >({
            query: ({ params, redisChannel }) => ({
                url: `/jenkins/logs/${params.jobname}/${params.build}`,
                method: 'GET',
                params: { redisChannel },
            }),
        }),
        getDetailedBuildsInfo: builder.query<JenkinsBuild[], JenkinsBuildsQueryParams>({
            query: (params) => ({
                url: `jenkins/builds/${params.jobname}`,
                method: 'GET',
                params: {
                    page: params.page,
                    limit: params.limit,
                    redisChannel: params.redisChannel,
                },
            }),
        }),
        getRedisChannels: builder.query<string[], void>({
            query: () => ({
                url: '/jenkins/redis/channels',
                method: 'GET',
            }),
        }),
    }),
});

export const { 
    useSearchJenkinsJobsQuery, 
    useTriggerJobWithParamsMutation, 
    useGetJobConfigsQuery
 } = jenkinsApi;

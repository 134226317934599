import { createApi } from '@reduxjs/toolkit/query/react';
import { _errorHandler } from '../utilits';
import { axiosBaseQuery } from '../../utils/services/rqt-axios';
import { MAGNUM_API_REDUCER_KEY } from '../consts';
import {IMagnumFilters, IMagnum } from './types';

export const magnumApi = createApi({
    reducerPath: MAGNUM_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery(),
    tagTypes: ['Magnum'],
    endpoints: (builder) => ({

        //* fetch Initial Magnum Data

        fetchInitialMagnumData: builder.query<IMagnum, Partial<IMagnumFilters>>({
            query: (params) => ({
                url: '/services/magnum/getInitialPhones',
                method: 'GET',
                params: params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // *fetch Filtered Phones

        fetchFilteredPhones: builder.mutation<IMagnum, Partial<IMagnumFilters>>({
            query: (params) => ({
                url: '/services/magnum/getPhonesByFilters',
                method: 'POST',
                data: params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // *fetch Input Options

        fetchInputOptions: builder.mutation<IMagnum, Partial<IMagnumFilters>>({
            query: (params) => ({
                url: '/services/magnum/getModelInputOptions',
                method: 'POST',
                data: params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

        // *fetch Uuid Options

        fetchUuidOptions: builder.mutation<IMagnum, Partial<IMagnumFilters>>({
            query: (params) => ({
                url: '/services/magnum/getUuidInputOptions',
                method: 'POST',
                data: params,
            }),
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                _errorHandler(dispatch, queryFulfilled);
            },
        }),

    }),
});


export const {
    useFetchInitialMagnumDataQuery,
    useFetchFilteredPhonesMutation,
    useFetchInputOptionsMutation,
    useFetchUuidOptionsMutation,
} = magnumApi;

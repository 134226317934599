import { runStatuses } from '../../../consts/runStatuses';

const statusColumns = (() => {
    const result = [];
    /* eslint-disable-next-line */
    runStatuses.map((status) => {
        if (status === 'PASSED_HAS_WARNINGS') {
            status = 'PASSED_HW';
        }
        result.push({ label: status });
    });
    return result;
})();

export const columnNamesArr = [
    { label: 'id' },
    { label: 'date' },
    { label: 'title' },
    { label: 'uploaded reports' },
    { label: 'reports' },
    { label: 'duration, min' },
    { label: 'Verifies STAT' },
    ...statusColumns,
    { label: 'progress, %' },
    { label: 'action' },
    { label: 'metadata' },
];

export const createOptions = (data) => {
    return data ? data.map((item) => ({
        value: item.id,
        label: `${item.id} - ("${item.title}")`,
    })) : []
}